<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card>
      <Header :event="event" focused/>
      <v-card-title class="headline">
        Configure Onboarding
      </v-card-title>
      <v-card-text>   
        <p>Configure basic onboarding and event rules. You can configure all options on the <strong>Settings screen</strong>.</p>
      </v-card-text>   

      <div v-if="event && request">
        <v-alert v-if="error || !valid" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>

        <v-form ref="form" v-model="valid" lazy-validation>

          <v-card-text>   
            <h3>Onboarding</h3>

            <v-input class="mb-4 input-row" label="How would you like to invite participants to join?" hide-details>
              <template>
                <v-btn-toggle v-model="request.verification_method" mandatory class="multiline-btns">
                  <v-btn value="ALLOWLIST" small>
                    Re-usable<br/>QR+Link
                  </v-btn>
                  <v-btn value="REG_DATA" small>
                    Personal<br/>Invites
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-input>
            <p v-if="request.verification_method === 'ALLOWLIST'" class=""><strong>Re-usable QR+Link:</strong> Share a generic QR code/join link to invite your team. Anyone with this QR code or join link may join.</p>
            <p v-if="request.verification_method === 'REG_DATA'" class=""><strong>Registration Data:</strong> Send personal invites with normalized employee information to invite your team. Upload an Excel file to configure who may join this event or manually manage registration data.</p>

            <p>Additional settings available on the <strong>Onboarding section</strong> on the <router-link :to="{name: 'eventmanagerEdit', params: {id: event.id} }">Settings Screen</router-link>.</p> 

            <h3>Event Rules</h3>
            <v-switch v-model="request.allow_manual_entry" persistent-hint hint="When enabled, manually entered activities (without gpx track nor proof) may qualify for this event." class="mt-0" label="Allow manual activity entry"></v-switch>

            <p>Additional settings available on the <strong>Rules section</strong> on the <router-link :to="{name: 'eventmanagerEdit', params: {id: event.id} }">Settings Screen</router-link>.</p> 


          </v-card-text>   

        </v-form>

        <v-card-text>   
          <v-btn class="mt-8 mb-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit">Save</v-btn>
          <v-alert v-if="error || !valid" class="mt-4" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>
        </v-card-text>   
      </div>      
    </v-card>
    
    <SupportButton/>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import EventImageUploadForm from "@/components/EventImageUploadForm";
import SupportButton from '@/components/SupportButton.vue'
import MarkdownEditor from '@/components/MarkdownEditor.vue'
import EventHeader from "@/components/EventHeader.vue";
import RegFieldEditor from "@/components/RegFieldEditor.vue";
import ToggleButtonInput from "@/components/ToggleButtonInput.vue";
import EventUtil from "@/util/eventUtil";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    DateWithTimeZonePicker,
    DateAndTimeWithTimeZonePicker,
    DistanceTextArea,
    EventImageUploadForm,
    ToggleButtonInput,
    SupportButton,
    MarkdownEditor,
    EventHeader,
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
      valid: true,
      error: null,
      event: null,
      request: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.error = null;
        try {
          var response = await eventManagerService.putOnboardingConfig(this.event.id, this.request);
          if (response.data.status === 'OK') {
            this.$router.push({ name: 'eventmanagerView', params: { id: this.eventUtil.event.id }});
            return;
          }
          else {
            this.error = response.data.msg;
          }
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
      }
    },

    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.request = {
        allow_manual_entry: this.event.allow_manual_entry,
        verification_method: this.event.verification_method,
      };
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

  },
  computed: {
    eventUtil() {
      return new EventUtil(this, this.event);
    },

    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Configure Onboarding', disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
</style>

